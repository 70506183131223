import { Percent } from "@aryze/sdk-core";
import { Trans } from "@lingui/macro";
import { useFiatOnRampButtonEnabled } from "featureFlags/flags/fiatOnRampButton";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import { RowBetween, RowFixed } from "../Row";
import SettingsTab from "../Settings";
import SwapBuyFiatButton from "./SwapBuyFiatButton";
import Column from "components/Column";
import { Span } from "@sentry/tracing";

const StyledSwapHeader = styled(RowBetween)`
  margin-bottom: 10px;

  min-height: 116.8px;
  
  position: relative;
  padding: 13px;
  background: #fff;
  border-radius: 16px;
}
`;

const HeaderButtonContainer = styled(RowFixed)`
  gap: 16px;
`;

const HeaderTitleWrapper = styled(Column)``;
const HeaderTitle = styled(Column)`
  color: rgb(17, 24, 28);
  letter-spacing: 0.001rem;
  font-weight: 700;
  line-height: 54px;
  font-size: 36px;
  text-transform: none;
  background-image: linear-gradient(45deg, #2774bc 0%, rgb(9, 170, 205) 70%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const HeaderSubTitle = styled(RowFixed)`
  color: rgb(17, 24, 28);
  font-size: 11px;
  text-transform: none;
  display: flex;
  align-items: center;
  letter-spacing: 0.01rem;
  font-size: 14px;
  margin: 0 0 0.625rem 0;
`;
const SwapHeaderBtn = styled(Column)`
  position: absolute;
  right: 0;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function SwapHeader({
  autoSlippage,
  chainId,
}: {
  autoSlippage: Percent;
  chainId?: number;
}) {
  const fiatOnRampButtonEnabled = useFiatOnRampButtonEnabled();

  return (
    <StyledSwapHeader>
      <HeaderButtonContainer>
        <ThemedText.SubHeader>
          <HeaderTitleWrapper>
            <HeaderTitle>Swap</HeaderTitle>
            <HeaderSubTitle>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <svg
                  style={{ marginRight: 2 }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  width="16"
                  height="16"
                >
                  <path d="M12.577 4.878a.75.75 0 01.919-.53l4.78 1.281a.75.75 0 01.531.919l-1.281 4.78a.75.75 0 01-1.449-.387l.81-3.022a19.407 19.407 0 00-5.594 5.203.75.75 0 01-1.139.093L7 10.06l-4.72 4.72a.75.75 0 01-1.06-1.061l5.25-5.25a.75.75 0 011.06 0l3.074 3.073a20.923 20.923 0 015.545-4.931l-3.042-.815a.75.75 0 01-.53-.919z"></path>
                </svg>
              </div>
              <div> Swap Digital Cash whenever you need</div>
            </HeaderSubTitle>
          </HeaderTitleWrapper>
        </ThemedText.SubHeader>
        {fiatOnRampButtonEnabled && <SwapBuyFiatButton />}
      </HeaderButtonContainer>

      <SwapHeaderBtn>
        <SettingsTab autoSlippage={autoSlippage} chainId={chainId} />
      </SwapHeaderBtn>
    </StyledSwapHeader>
  );
}
