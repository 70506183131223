import { TraceEvent } from "@uniswap/analytics";
import {
    BrowserEvent,
    InterfaceElementName,
    InterfaceEventName,
} from "@uniswap/analytics-events";
import { useWeb3React } from "@web3-react/core";
import { useToggleAccountDrawer } from "components/AccountDrawer";
import Loader from "components/Icons/LoadingSpinner";
import { ActivationStatus, useActivationState } from "connection/activate";
import { Connection } from "connection/types";
import styled from "styled-components/macro";
import { useIsDarkMode } from "theme/components/ThemeToggle";
import { flexColumnNoWrap, flexRowNoWrap } from "theme/styles";

import NewBadge from "./NewBadge";

const OptionCardLeft = styled.div`
  ${flexColumnNoWrap};
  flex-direction: row;
  align-items: center;
`;

const OptionCardClickable = styled.button<{ selected: boolean }>`
  align-items: center;
  background-color: unset;
  border: none;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
  opacity: ${({ disabled, selected }) => (disabled && !selected ? "0.5" : "1")};
  padding: 18px;
  transition: ${({ theme }) => theme.transition.duration.fast};
`;

const HeaderText = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.color === "blue"
      ? ({ theme }) => theme.accentAction
      : ({ theme }) => theme.textPrimary};
  font-size: 16px;
  font-weight: 600;
  padding: 0 8px;
`;
const IconWrapper = styled.div`
  ${flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  img {
    ${({ theme }) =>
      !theme.darkMode && `border: 1px solid ${theme.backgroundOutline}`};
    border-radius: 12px;
  }
  & > img,
  span {
    height: 40px;
    width: 40px;
  }
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    align-items: flex-end;
  `};
`;

const Wrapper = styled.div<{ disabled: boolean }>`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;

  background-color: ${({ theme }) => theme.backgroundModule};

  &:hover {
    cursor: ${({ disabled }) => !disabled && "pointer"};
    background-color: ${({ theme, disabled }) => !disabled && theme.hoverState};
  }
  &:focus {
    background-color: ${({ theme, disabled }) => !disabled && theme.hoverState};
  }
`;

interface OptionProps {
  connection: Connection;
}
export default function Option({ connection }: OptionProps) {
  const { activationState, tryActivation } = useActivationState();
  const toggleAccountDrawer = useToggleAccountDrawer();
  const { chainId } = useWeb3React();
  const activate = () =>
    tryActivation(connection, toggleAccountDrawer, chainId);

  const isSomeOptionPending =
    activationState.status === ActivationStatus.PENDING;
  const isCurrentOptionPending =
    isSomeOptionPending && activationState.connection.type === connection.type;
  const isDarkMode = useIsDarkMode();

  return (
    <Wrapper disabled={isSomeOptionPending}>
      <TraceEvent
        events={[BrowserEvent.onClick]}
        name={InterfaceEventName.WALLET_SELECTED}
        properties={{ wallet_type: connection.getName() }}
        element={InterfaceElementName.WALLET_TYPE_OPTION}
      >
        <OptionCardClickable
          disabled={isSomeOptionPending}
          onClick={activate}
          selected={isCurrentOptionPending}
          data-testid={`wallet-option-${connection.type}`}
        >
          <OptionCardLeft>
            <IconWrapper>
              <img
                src={connection.getIcon?.(isDarkMode)}
                alt={connection.getName()}
              />
            </IconWrapper>
            <HeaderText>{connection.getName()}</HeaderText>
            {connection.isNew && <NewBadge />}
          </OptionCardLeft>
          {isCurrentOptionPending && <Loader />}
        </OptionCardClickable>
      </TraceEvent>
    </Wrapper>
  );
}
