import {
  ChainId,
  Currency,
  CurrencyAmount,
  Percent,
  Token,
} from "@aryze/sdk-core";
import { UNIVERSAL_ROUTER_ADDRESS } from "@aryze/universal-router-sdk";
import { Trans } from "@lingui/macro";
import {
  sendAnalyticsEvent,
  Trace,
  TraceEvent,
  useTrace,
} from "@uniswap/analytics";
import {
  BrowserEvent,
  InterfaceElementName,
  InterfaceEventName,
  InterfacePageName,
  InterfaceSectionName,
  SharedEventName,
  SwapEventName,
} from "@uniswap/analytics-events";
import { formatCurrencyAmount, NumberType } from "@uniswap/conedison/format";
import { useWeb3React } from "@web3-react/core";
import { useToggleAccountDrawer } from "components/AccountDrawer";
import { sendEvent } from "components/analytics";
import { NetworkAlert } from "components/NetworkAlert/NetworkAlert";
import PriceImpactModal from "components/swap/PriceImpactModal";
import PriceImpactWarning from "components/swap/PriceImpactWarning";
import SwapDetailsDropdown from "components/swap/SwapDetailsDropdown";
import TokenSafetyModal from "components/TokenSafety/TokenSafetyModal";
import { getChainInfo, getChainInfoOrDefault } from "constants/chainInfo";
import { asSupportedChain, isSupportedChain } from "constants/chains";
import useENSAddress from "hooks/useENSAddress";
import { useMaxAmountIn } from "hooks/useMaxAmountIn";
import usePermit2Allowance, { AllowanceState } from "hooks/usePermit2Allowance";
import usePrevious from "hooks/usePrevious";
import { useSwapCallback } from "hooks/useSwapCallback";
import { useSwitchChain } from "hooks/useSwitchChain";
import { useUSDPrice } from "hooks/useUSDPrice";
import JSBI from "jsbi";
import { formatEventPropertiesForTrade } from "lib/utils/analytics";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { ArrowDown } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import { Text } from "rebass";
import { useAppSelector } from "state/hooks";
import { InterfaceTrade, TradeState } from "state/routing/types";
import styled, { useTheme } from "styled-components/macro";
import { didUserReject } from "utils/swapErrorToUserReadableMessage";

import AddressInputPanel from "../../components/AddressInputPanel";
import {
  ButtonError,
  ButtonLight,
  ButtonPrimary,
} from "../../components/Button";
import { GrayCard } from "../../components/Card";
import { AutoColumn } from "../../components/Column";
import SwapCurrencyInputPanel from "../../components/CurrencyInputPanel/SwapCurrencyInputPanel";
import { AutoRow } from "../../components/Row";
import confirmPriceImpactWithoutFee from "../../components/swap/confirmPriceImpactWithoutFee";
import ConfirmSwapModal from "../../components/swap/ConfirmSwapModal";
import {
  ArrowWrapper,
  PageWrapper,
  SwapWrapper,
} from "../../components/swap/styleds";
import SwapHeader from "../../components/swap/SwapHeader";
import { SwitchLocaleLink } from "../../components/SwitchLocaleLink";
import { getSwapCurrencyId, TOKEN_SHORTHANDS } from "../../constants/tokens";
import { useCurrency, useDefaultActiveTokens } from "../../hooks/Tokens";
import { useIsSwapUnsupported } from "../../hooks/useIsSwapUnsupported";
import useWrapCallback, {
  WrapErrorText,
  WrapType,
} from "../../hooks/useWrapCallback";
import { Field, replaceSwapState } from "../../state/swap/actions";
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
} from "../../state/swap/hooks";
import swapReducer, {
  initialState as initialSwapState,
  SwapState,
} from "../../state/swap/reducer";
import { LinkStyledButton, ThemedText } from "../../theme";
import { computeFiatValuePriceImpact } from "../../utils/computeFiatValuePriceImpact";
import { maxAmountSpend } from "../../utils/maxAmountSpend";
import {
  computeRealizedPriceImpact,
  warningSeverity,
} from "../../utils/prices";
import { log } from "console";

export const ArrowContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

const SwapSection = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 12px;
  padding: 13px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;

  &:before {
    box-sizing: border-box;
    background-size: 100%;
    border-radius: inherit;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    pointer-events: none;
    content: "";
    border: 1px solid ${({ theme }) => theme.backgroundModule};
  }

  &:hover:before {
    border-color: ${({ theme }) => theme.stateOverlayHover};
  }

  &:focus-within:before {
    border-color: ${({ theme }) => theme.stateOverlayPressed};
  }
`;

const OutputSwapSection = styled(SwapSection)`
  border-bottom: ${({ theme }) => `1px solid ${theme.backgroundSurface}`};
`;

function getIsValidSwapQuote(
  trade: InterfaceTrade | undefined,
  tradeState: TradeState,
  swapInputError?: ReactNode
): boolean {
  return Boolean(swapInputError && trade && tradeState === TradeState.VALID);
}

function largerPercentValue(a?: Percent, b?: Percent) {
  if (a && b) {
    return a.greaterThan(b) ? a : b;
  } else if (a) {
    return a;
  } else if (b) {
    return b;
  }
  return undefined;
}

const TRADE_STRING = "SwapRouter";

export default function SwapPage({ className }: { className?: string }) {
  const { chainId: connectedChainId } = useWeb3React();
  const loadedUrlParams = useDefaultsFromURLSearch();

  const location = useLocation();

  return (
    <Trace page={InterfacePageName.SWAP_PAGE} shouldLogImpression>
      <PageWrapper>
        <Swap
          className={className}
          chainId={connectedChainId}
          prefilledState={{
            [Field.INPUT]: {
              currencyId: loadedUrlParams?.[Field.INPUT]?.currencyId,
            },
            [Field.OUTPUT]: {
              currencyId: loadedUrlParams?.[Field.OUTPUT]?.currencyId,
            },
          }}
        />
        <NetworkAlert />
      </PageWrapper>
      {/* {location.pathname === "/swap" && <SwitchLocaleLink />} */}
    </Trace>
  );
}

/**
 * The swap component displays the swap interface, manages state for the swap, and triggers onchain swaps.
 *
 * In most cases, chainId should refer to the connected chain, i.e. `useWeb3React().chainId`.
 * However if this component is being used in a context that displays information from a different, unconnected
 * chain (e.g. the TDP), then chainId should refer to the unconnected chain.
 */
export function Swap({
  className,
  prefilledState = {},
  chainId,
  onCurrencyChange,
  disableTokenInputs = false,
}: {
  className?: string;
  prefilledState?: Partial<SwapState>;
  chainId?: ChainId;
  onCurrencyChange?: (
    selected: Pick<SwapState, Field.INPUT | Field.OUTPUT>
  ) => void;
  disableTokenInputs?: boolean;
}) {
  const { account, chainId: connectedChainId, connector } = useWeb3React();
  const trace = useTrace();

  // token warning stuff
  const prefilledInputCurrency = useCurrency(
    prefilledState?.[Field.INPUT]?.currencyId
  );
  const prefilledOutputCurrency = useCurrency(
    prefilledState?.[Field.OUTPUT]?.currencyId
  );

  const [loadedInputCurrency, setLoadedInputCurrency] = useState(
    prefilledInputCurrency
  );
  const [loadedOutputCurrency, setLoadedOutputCurrency] = useState(
    prefilledOutputCurrency
  );

  useEffect(() => {
    setLoadedInputCurrency(prefilledInputCurrency);
    setLoadedOutputCurrency(prefilledOutputCurrency);
  }, [prefilledInputCurrency, prefilledOutputCurrency]);

  const [dismissTokenWarning, setDismissTokenWarning] =
    useState<boolean>(false);
  const [showPriceImpactModal, setShowPriceImpactModal] =
    useState<boolean>(false);

  const urlLoadedTokens: Token[] = useMemo(
    () =>
      [loadedInputCurrency, loadedOutputCurrency]?.filter(
        (c): c is Token => c?.isToken ?? false
      ) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  );
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true);
  }, []);

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useDefaultActiveTokens(chainId);
  const importTokensNotInDefault = useMemo(
    () =>
      urlLoadedTokens &&
      urlLoadedTokens
        .filter((token: Token) => {
          return !(token.address in defaultTokens);
        })
        .filter((token: Token) => {
          // Any token addresses that are loaded from the shorthands map do not need to show the import URL
          const supported = asSupportedChain(chainId);
          if (!supported) return true;
          return !Object.keys(TOKEN_SHORTHANDS).some((shorthand) => {
            const shorthandTokenAddress =
              TOKEN_SHORTHANDS[shorthand][supported];
            return (
              shorthandTokenAddress && shorthandTokenAddress === token.address
            );
          });
        }),
    [chainId, defaultTokens, urlLoadedTokens]
  );

  const theme = useTheme();

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer();

  // swap state
  const [state, dispatch] = useReducer(swapReducer, {
    ...initialSwapState,
    ...prefilledState,
  });
  const { typedValue, recipient, independentField } = state;

  const previousConnectedChainId = usePrevious(connectedChainId);
  const previousPrefilledState = usePrevious(prefilledState);
  useEffect(() => {
    const combinedInitialState = { ...initialSwapState, ...prefilledState };
    const chainChanged =
      previousConnectedChainId && previousConnectedChainId !== connectedChainId;
    const prefilledInputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.INPUT]?.currencyId !==
        prefilledState?.[Field.INPUT]?.currencyId;
    const prefilledOutputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.OUTPUT]?.currencyId !==
        prefilledState?.[Field.OUTPUT]?.currencyId;
    if (chainChanged || prefilledInputChanged || prefilledOutputChanged) {
      dispatch(
        replaceSwapState({
          ...initialSwapState,
          ...prefilledState,
          field: combinedInitialState.independentField ?? Field.INPUT,
          inputCurrencyId: combinedInitialState.INPUT.currencyId ?? undefined,
          outputCurrencyId: combinedInitialState.OUTPUT.currencyId ?? undefined,
        })
      );
      // reset local state
      setSwapState({
        tradeToConfirm: undefined,
        swapError: undefined,
        showConfirm: false,
        txHash: undefined,
      });
    }
  }, [
    connectedChainId,
    prefilledState,
    previousConnectedChainId,
    previousPrefilledState,
  ]);

  const {
    trade: { state: tradeState, trade, method },
    allowedSlippage,
    autoSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo(state, chainId);

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const { address: recipientAddress } = useENSAddress(recipient);

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]:
              independentField === Field.INPUT
                ? parsedAmount
                : trade?.inputAmount,
            [Field.OUTPUT]:
              independentField === Field.OUTPUT
                ? parsedAmount
                : trade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, trade]
  );

  const fiatValueInput = useUSDPrice(parsedAmounts[Field.INPUT]);
  const fiatValueOutput = useUSDPrice(parsedAmounts[Field.OUTPUT]);
  //ARYZE
  // const fiatValueInput = useUSDPrice(
  //   parsedAmounts[Field.INPUT],
  //   currencies[Field.INPUT] ?? undefined
  // );
  // const fiatValueOutput = useUSDPrice(
  //   parsedAmounts[Field.OUTPUT],
  //   currencies[Field.OUTPUT] ?? undefined
  // );
  const showFiatValueInput = Boolean(parsedAmounts[Field.INPUT]);
  const showFiatValueOutput = Boolean(parsedAmounts[Field.OUTPUT]);

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [
      !trade?.swaps,
      TradeState.LOADING === tradeState,
      TradeState.LOADING === tradeState && Boolean(trade),
    ],
    [trade, tradeState]
  );

  const fiatValueTradeInput = useUSDPrice(trade?.inputAmount);
  const fiatValueTradeOutput = useUSDPrice(trade?.outputAmount);
  const stablecoinPriceImpact = useMemo(
    () =>
      routeIsSyncing || !trade
        ? undefined
        : computeFiatValuePriceImpact(
            fiatValueTradeInput.data,
            fiatValueTradeOutput.data
          ),
    [fiatValueTradeInput, fiatValueTradeOutput, routeIsSyncing, trade]
  );

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  } = useSwapActionHandlers(dispatch);
  const isValid = !swapInputError;
  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput]
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput]
  );

  const navigate = useNavigate();
  const swapIsUnsupported = useIsSwapUnsupported(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT]
  );

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true);
    navigate("/swap/");
  }, [navigate]);

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapError, txHash }, setSwapState] =
    useState<{
      showConfirm: boolean;
      tradeToConfirm?: InterfaceTrade;
      swapError?: Error;
      txHash?: string;
    }>({
      showConfirm: false,
      tradeToConfirm: undefined,
      swapError: undefined,
      txHash: undefined,
    });

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ""
        : formatCurrencyAmount(
            parsedAmounts[dependentField],
            NumberType.SwapTradeAmount,
            ""
          ),
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  );

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
      currencies[Field.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  );

  const maximumAmountIn = useMaxAmountIn(trade, allowedSlippage);
  const allowance = usePermit2Allowance(
    maximumAmountIn ??
      (parsedAmounts[Field.INPUT]?.currency.isToken
        ? (parsedAmounts[Field.INPUT] as CurrencyAmount<Token>)
        : undefined),
    isSupportedChain(chainId) ? UNIVERSAL_ROUTER_ADDRESS(chainId) : undefined
  );

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  );
  const showMaxButton = Boolean(
    maxInputAmount?.greaterThan(0) &&
      !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount)
  );
  const swapFiatValues = useMemo(() => {
    return {
      amountIn: fiatValueTradeInput.data,
      amountOut: fiatValueTradeOutput.data,
    };
  }, [fiatValueTradeInput, fiatValueTradeOutput]);

  // the callback to execute the swap
  const { callback: swapCallback } = useSwapCallback(
    trade,
    swapFiatValues,
    allowedSlippage,
    allowance.state === AllowanceState.ALLOWED
      ? allowance.permitSignature
      : undefined
  );

  const handleContinueToReview = useCallback(() => {
    setSwapState({
      tradeToConfirm: trade,
      swapError: undefined,
      showConfirm: true,
      txHash: undefined,
    });
  }, [trade]);

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      return;
    }
    if (
      stablecoinPriceImpact &&
      !confirmPriceImpactWithoutFee(stablecoinPriceImpact)
    ) {
      return;
    }
    setSwapState((currentState) => ({
      ...currentState,
      swapError: undefined,
      txHash: undefined,
    }));
    swapCallback()
      .then((hash) => {
        setSwapState((currentState) => ({
          ...currentState,
          swapError: undefined,
          txHash: hash,
        }));
        sendEvent({
          category: "Swap",
          action: "transaction hash",
          label: hash,
        });
        sendEvent({
          category: "Swap",
          action:
            recipient === null
              ? "Swap w/o Send"
              : (recipientAddress ?? recipient) === account
              ? "Swap w/o Send + recipient"
              : "Swap w/ Send",
          label: [
            TRADE_STRING,
            trade?.inputAmount?.currency?.symbol,
            trade?.outputAmount?.currency?.symbol,
            "MH",
          ].join("/"),
        });
      })
      .catch((error) => {
        console.log(error);

        if (!didUserReject(error)) {
          sendAnalyticsEvent(SwapEventName.SWAP_ERROR, {
            confirmedTrade: tradeToConfirm,
          });
        }
        setSwapState((currentState) => ({
          ...currentState,
          swapError: error,
          txHash: undefined,
        }));
      });
  }, [
    swapCallback,
    stablecoinPriceImpact,
    recipient,
    recipientAddress,
    account,
    trade?.inputAmount?.currency?.symbol,
    trade?.outputAmount?.currency?.symbol,
    tradeToConfirm,
  ]);

  // errors
  const [swapQuoteReceivedDate, setSwapQuoteReceivedDate] = useState<
    Date | undefined
  >();

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    const marketPriceImpact = trade?.priceImpact
      ? computeRealizedPriceImpact(trade)
      : undefined;
    const largerPriceImpact = largerPercentValue(
      marketPriceImpact,
      stablecoinPriceImpact
    );
    return {
      priceImpactSeverity: warningSeverity(largerPriceImpact),
      largerPriceImpact,
    };
  }, [stablecoinPriceImpact, trade]);

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((currentState) => ({ ...currentState, showConfirm: false }));
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, "");
    }
  }, [onUserInput, txHash]);

  const handleAcceptChanges = useCallback(() => {
    setSwapState((currentState) => ({
      ...currentState,
      tradeToConfirm: trade,
    }));
  }, [trade]);

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      onCurrencySelection(Field.INPUT, inputCurrency);
      onCurrencyChange?.({
        [Field.INPUT]: {
          currencyId: getSwapCurrencyId(inputCurrency),
        },
        [Field.OUTPUT]: state[Field.OUTPUT],
      });
    },
    [onCurrencyChange, onCurrencySelection, state]
  );

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact());
    sendEvent({
      category: "Swap",
      action: "Max",
    });
  }, [maxInputAmount, onUserInput]);

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency);
      onCurrencyChange?.({
        [Field.INPUT]: state[Field.INPUT],
        [Field.OUTPUT]: {
          currencyId: getSwapCurrencyId(outputCurrency),
        },
      });
    },
    [onCurrencyChange, onCurrencySelection, state]
  );

  const showPriceImpactWarning = largerPriceImpact && priceImpactSeverity > 3;

  const prevTrade = usePrevious(trade);
  useEffect(() => {
    if (!trade || prevTrade === trade) return; // no new swap quote to log

    setSwapQuoteReceivedDate(new Date());
    sendAnalyticsEvent(SwapEventName.SWAP_QUOTE_RECEIVED, {
      ...formatEventPropertiesForTrade(
        trade,
        allowedSlippage,
        trade.gasUseEstimateUSD ?? undefined,
        method
      ),
      ...trace,
    });
  }, [prevTrade, trade, trace, allowedSlippage, method]);

  const showDetailsDropdown = Boolean(
    !showWrap &&
      userHasSpecifiedInputOutput &&
      (trade || routeIsLoading || routeIsSyncing)
  );

  const switchChain = useSwitchChain();
  const switchingChain = useAppSelector(
    (state) => state.wallets.switchingChain
  );

  return (
    <SwapWrapper chainId={chainId} className={className} id="swap-page">
      <TokenSafetyModal
        isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
        tokenAddress={importTokensNotInDefault[0]?.address}
        secondTokenAddress={importTokensNotInDefault[1]?.address}
        onContinue={handleConfirmTokenWarning}
        onCancel={handleDismissTokenWarning}
        showCancel={true}
      />
      <SwapHeader autoSlippage={autoSlippage} chainId={chainId} />
      {trade && showConfirm && (
        <ConfirmSwapModal
          trade={trade}
          originalTrade={tradeToConfirm}
          onAcceptChanges={handleAcceptChanges}
          txHash={txHash}
          allowedSlippage={allowedSlippage}
          onConfirm={handleSwap}
          allowance={allowance}
          swapError={swapError}
          onDismiss={handleConfirmDismiss}
          swapQuoteReceivedDate={swapQuoteReceivedDate}
          fiatValueInput={fiatValueTradeInput}
          fiatValueOutput={fiatValueTradeOutput}
        />
      )}
      {showPriceImpactModal && showPriceImpactWarning && (
        <PriceImpactModal
          priceImpact={largerPriceImpact}
          onDismiss={() => setShowPriceImpactModal(false)}
          onContinue={() => {
            setShowPriceImpactModal(false);
            handleContinueToReview();
          }}
        />
      )}

      <div style={{ display: "relative" }}>
        <SwapSection>
          <Trace section={InterfaceSectionName.CURRENCY_INPUT_PANEL}>
            <SwapCurrencyInputPanel
              label={<Trans>You sell</Trans>}
              disabled={disableTokenInputs}
              value={formattedAmounts[Field.INPUT]}
              showMaxButton={showMaxButton}
              currency={currencies[Field.INPUT] ?? null}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              fiatValue={showFiatValueInput ? fiatValueInput : undefined}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              showCommonBases
              id={InterfaceSectionName.CURRENCY_INPUT_PANEL}
              loading={independentField === Field.OUTPUT && routeIsSyncing}
            />
          </Trace>
        </SwapSection>
        <ArrowWrapper clickable={isSupportedChain(chainId)}>
          <TraceEvent
            events={[BrowserEvent.onClick]}
            name={SwapEventName.SWAP_TOKENS_REVERSED}
            element={InterfaceElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON}
          >
            <ArrowContainer
              data-testid="swap-currency-button"
              onClick={() => {
                !disableTokenInputs && onSwitchTokens();
              }}
              color={theme.textPrimary}
            >
              <ArrowDown
                size="16"
                color={
                  currencies[Field.INPUT] && currencies[Field.OUTPUT]
                    ? theme.textPrimary
                    : theme.textTertiary
                }
              />
            </ArrowContainer>
          </TraceEvent>
        </ArrowWrapper>
      </div>
      <AutoColumn gap="xs">
        <div>
          <OutputSwapSection>
            <Trace section={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}>
              <SwapCurrencyInputPanel
                value={formattedAmounts[Field.OUTPUT]}
                disabled={disableTokenInputs}
                onUserInput={handleTypeOutput}
                label={<Trans>You buy</Trans>}
                showMaxButton={false}
                hideBalance={false}
                fiatValue={showFiatValueOutput ? fiatValueOutput : undefined}
                priceImpact={stablecoinPriceImpact}
                currency={currencies[Field.OUTPUT] ?? null}
                onCurrencySelect={handleOutputSelect}
                otherCurrency={currencies[Field.INPUT]}
                showCommonBases
                id={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}
                loading={independentField === Field.INPUT && routeIsSyncing}
              />
            </Trace>
            {recipient !== null && !showWrap ? (
              <>
                <AutoRow justify="space-between" style={{ padding: "0 1rem" }}>
                  <ArrowWrapper clickable={false}>
                    <ArrowDown size="16" color={theme.textSecondary} />
                  </ArrowWrapper>
                  <LinkStyledButton
                    id="remove-recipient-button"
                    onClick={() => onChangeRecipient(null)}
                  >
                    <Trans>- Remove recipient</Trans>
                  </LinkStyledButton>
                </AutoRow>
                <AddressInputPanel
                  id="recipient"
                  value={recipient}
                  onChange={onChangeRecipient}
                />
              </>
            ) : null}
          </OutputSwapSection>
        </div>
        {showDetailsDropdown && (
          <SwapDetailsDropdown
            trade={trade}
            syncing={routeIsSyncing}
            loading={routeIsLoading}
            allowedSlippage={allowedSlippage}
          />
        )}
        {showPriceImpactWarning && (
          <PriceImpactWarning priceImpact={largerPriceImpact} />
        )}
        <div>
          {swapIsUnsupported ? (
            <ButtonPrimary disabled={true}>
              <ThemedText.DeprecatedMain mb="4px">
                <Trans>Unsupported Asset</Trans>
              </ThemedText.DeprecatedMain>
            </ButtonPrimary>
          ) : switchingChain ? (
            <ButtonPrimary disabled={true}>
              <Trans>Connecting to {getChainInfo(switchingChain)?.label}</Trans>
            </ButtonPrimary>
          ) : !account ? (
            <TraceEvent
              events={[BrowserEvent.onClick]}
              name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
              properties={{
                received_swap_quote: getIsValidSwapQuote(
                  trade,
                  tradeState,
                  swapInputError
                ),
              }}
              element={InterfaceElementName.CONNECT_WALLET_BUTTON}
            >
              <ButtonLight onClick={toggleWalletDrawer} fontWeight={600}>
                <Trans>Connect Wallet</Trans>
              </ButtonLight>
            </TraceEvent>
          ) : !switchingChain && chainId && chainId !== ChainId.POLYGON ? (
            <ButtonPrimary
              onClick={async () => {
                try {
                  await switchChain(connector, ChainId.POLYGON);
                } catch (error) {
                  if (didUserReject(error)) {
                    // Ignore error, which keeps the user on the previous chain.
                  } else {
                    // TODO(WEB-3306): This UX could be improved to show an error state.
                    throw error;
                  }
                }
              }}
            >
              Connect to {getChainInfoOrDefault(ChainId.POLYGON)?.label}
            </ButtonPrimary>
          ) : showWrap ? (
            <ButtonPrimary
              disabled={Boolean(wrapInputError)}
              onClick={onWrap}
              fontWeight={600}
              data-testid="wrap-button"
            >
              {wrapInputError ? (
                <WrapErrorText wrapInputError={wrapInputError} />
              ) : wrapType === WrapType.WRAP ? (
                <Trans>Wrap</Trans>
              ) : wrapType === WrapType.UNWRAP ? (
                <Trans>Unwrap</Trans>
              ) : null}
            </ButtonPrimary>
          ) : routeNotFound &&
            userHasSpecifiedInputOutput &&
            !routeIsLoading &&
            !routeIsSyncing ? (
            <GrayCard style={{ textAlign: "center" }}>
              <ThemedText.DeprecatedMain mb="4px">
                <Trans>Insufficient liquidity for this trade.</Trans>
              </ThemedText.DeprecatedMain>
            </GrayCard>
          ) : (
            <TraceEvent
              events={[BrowserEvent.onClick]}
              name={SharedEventName.ELEMENT_CLICKED}
              element={InterfaceElementName.SWAP_BUTTON}
            >
              <ButtonError
                onClick={() => {
                  showPriceImpactWarning
                    ? setShowPriceImpactModal(true)
                    : handleContinueToReview();
                }}
                id="swap-button"
                data-testid="swap-button"
                disabled={!isValid || routeIsSyncing || routeIsLoading}
                error={
                  isValid &&
                  priceImpactSeverity > 2 &&
                  allowance.state === AllowanceState.ALLOWED
                }
              >
                <Text fontSize={20} fontWeight={600}>
                  {swapInputError ? (
                    swapInputError
                  ) : routeIsSyncing || routeIsLoading ? (
                    <Trans>Swap</Trans>
                  ) : priceImpactSeverity > 2 ? (
                    <Trans>Swap Anyway</Trans>
                  ) : (
                    <Trans>Swap</Trans>
                  )}
                </Text>
              </ButtonError>
            </TraceEvent>
          )}
        </div>
      </AutoColumn>
    </SwapWrapper>
  );
}
