import { CurrencyAmount, Token } from "@aryze/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { useTokenBalancesWithLoadingIndicator } from "lib/hooks/useCurrencyBalance";
import { useMemo } from "react";

import { useDefaultActiveTokens } from "../../hooks/Tokens";

export {
  default as useCurrencyBalance,
  useCurrencyBalances,
  useNativeCurrencyBalances,
  useTokenBalance,
  useTokenBalances,
  useTokenBalancesWithLoadingIndicator,
} from "lib/hooks/useCurrencyBalance";

// mimics useAllBalances
export function useAllTokenBalances(): [
  { [tokenAddress: string]: CurrencyAmount<Token> | undefined },
  boolean
] {
  const { account, chainId } = useWeb3React();
  const allTokens = useDefaultActiveTokens(chainId);
  const allTokensArray = useMemo(
    () => Object.values(allTokens ?? {}),
    [allTokens]
  );
  const [balances, balancesIsLoading] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    allTokensArray
  );
  return [balances ?? {}, balancesIsLoading];
}
