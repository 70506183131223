import { ChainId } from "@aryze/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { getConnection } from "connection";
import { didUserReject } from "connection/utils";
import { useCallback } from "react";
import { addPopup } from "state/application/reducer";
import { useAppDispatch } from "state/hooks";

import { useSwitchChain } from "./useSwitchChain";

export default function useSelectChain() {
  const dispatch = useAppDispatch();
  const { connector } = useWeb3React();
  const switchChain = useSwitchChain();

  return useCallback(
    async (targetChain: ChainId) => {
      if (!connector) return;

      const connection = getConnection(connector);

      try {
        await switchChain(connector, targetChain);
      } catch (error) {
        if (
          !didUserReject(connection, error) &&
          error.code !== -32002 /* request already pending */
        ) {
          console.error("Failed to switch networks", error);
          dispatch(
            addPopup({
              content: { failedSwitchNetwork: targetChain },
              key: "failed-network-switch",
            })
          );
        }
      }
    },
    [connector, dispatch, switchChain]
  );
}
