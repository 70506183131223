import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { useAccountDrawer } from "components/AccountDrawer";
import Web3Status from "components/Web3Status";
import { chainIdToBackendName } from "graphql/data/util";
import { useDisableNFTRoutes } from "hooks/useDisableNFTRoutes";
import { useIsNftPage } from "hooks/useIsNftPage";
import { useIsPoolsPage } from "hooks/useIsPoolsPage";
import { Box } from "nft/components/Box";
import { Row } from "nft/components/Flex";
import { useProfilePageState } from "nft/hooks";
import { ProfilePageStateType } from "nft/types";
import { ReactNode, useCallback } from "react";
import {
  NavLink,
  NavLinkProps,
  Path,
  useLocation,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components/macro";

import { useIsNavSearchInputVisible } from "../../nft/hooks/useIsNavSearchInputVisible";
import { Bag } from "./Bag";
import Blur from "./Blur";
import { ChainSelector } from "./ChainSelector";
import { MenuDropdown } from "./MenuDropdown";
import { SearchBar } from "./SearchBar";
import * as styles from "./style.css";

const Nav = styled.nav`
  padding: ${({ theme }) => `${theme.navVerticalPad}px 12px`};
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: 2;
`;

interface MenuItemProps {
  href: string;
  id?: NavLinkProps["id"];
  isActive?: boolean;
  children: ReactNode;
  dataTestId?: string;
  external?: boolean;
}

const MenuItem = ({
  href,
  dataTestId,
  id,
  isActive,
  children,
  external,
}: MenuItemProps) => {
  return external ? (
    <a
      href={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{ textDecoration: "none" }}
      data-testid={dataTestId}
    >
      {children}
    </a>
  ) : (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{ textDecoration: "none" }}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  );
};

export const PageTabs = () => {
  const { pathname } = useLocation();
  const { chainId: connectedChainId } = useWeb3React();
  const chainName = chainIdToBackendName(connectedChainId);

  const isPoolActive = useIsPoolsPage();
  const isNftPage = useIsNftPage();

  const shouldDisableNFTRoutes = useDisableNFTRoutes();

  return (
    <>
      <MenuItem
        href="/swap"
        isActive={pathname.startsWith("/swap") || pathname == "/"}
      >
        <Trans>Swap</Trans>
      </MenuItem>

      <Box display={{ sm: "flex", lg: "flex", xxl: "flex" }} width="full">
        <MenuItem
          href="/pools"
          dataTestId="pool-nav-link"
          isActive={isPoolActive}
        >
          <Trans>Liquidity</Trans>
        </MenuItem>
      </Box>
      <Box display={{ sm: "flex", lg: "flex", xxl: "flex" }} width="full">
        <MenuItem external={true} href={"https://mama.io/OnRamp/"}>
          <Trans>On/Off-Ramp</Trans>
        </MenuItem>
      </Box>

      <Box display={{ sm: "flex", lg: "flex", xxl: "flex" }} width="full">
        <MenuItem external={true} href={"https://mama.io/reForge/"}>
          <Trans>reForge</Trans>
        </MenuItem>
      </Box>
      <Box display={{ sm: "none", lg: "flex", xxl: "flex" }} width="full">
        <MenuItem external={true} href={"https://mama.io/Launchpad/"}>
          <Trans>Launchpad</Trans>
        </MenuItem>
      </Box>
      <Box marginY={{ sm: "4", md: "unset" }}>
        <MenuDropdown />
      </Box>
    </>
  );
};

const Navbar = ({ blur }: { blur: boolean }) => {
  const isNftPage = useIsNftPage();
  const sellPageState = useProfilePageState((state) => state.state);
  const navigate = useNavigate();
  const isNavSearchInputVisible = useIsNavSearchInputVisible();

  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer();

  const handleUniIconClick = useCallback(() => {
    if (accountDrawerOpen) {
      toggleAccountDrawer();
    }
    window.location.replace("https://mama.io");
  }, [accountDrawerOpen, navigate, toggleAccountDrawer]);

  return (
    <>
      {blur && <Blur />}
      <Nav>
        <Box display="flex" height="full" flexWrap="nowrap">
          <Box className={styles.leftSideContainer}>
            <Box className={styles.logoContainer}>
              <img
                width="44"
                height="30"
                data-testid="mama-logo"
                className={styles.logo}
                onClick={handleUniIconClick}
                src="/images/mama-logo.svg"
              />
            </Box>
            {!isNftPage && (
              <Box display={{ sm: "flex", lg: "none" }}>
                <ChainSelector leftAlign={true} />
              </Box>
            )}
            <Row display={{ sm: "none", lg: "flex" }}>
              <PageTabs />
            </Row>
          </Box>
          <Box
            className={styles.searchContainer}
            {...(isNavSearchInputVisible && {
              display: "flex",
            })}
          >
            {/* <SearchBar /> */}
          </Box>
          <Box className={styles.rightSideContainer}>
            <Row gap="12">
              <Box
                position="relative"
                display={isNavSearchInputVisible ? "none" : { sm: "flex" }}
              >
                {/* <SearchBar /> */}
              </Box>
              {isNftPage && sellPageState !== ProfilePageStateType.LISTING && (
                <Bag />
              )}
              {!isNftPage && (
                <Box display={{ sm: "none", lg: "flex" }}>
                  <ChainSelector />
                </Box>
              )}

              <Web3Status />
            </Row>
          </Box>
        </Box>
      </Nav>
    </>
  );
};

export default Navbar;
