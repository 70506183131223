import { ChainId } from "@aryze/sdk-core";
import { transparentize } from "polished";
import { ReactNode } from "react";
import { AlertTriangle } from "react-feather";
import styled, { css } from "styled-components/macro";
import { Z_INDEX } from "theme/zIndex";

import { AutoColumn } from "../Column";

export const PageWrapper = styled.div`
  padding: 68px 8px 0px;
  max-width: 472px;
  width: 100%;

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.md}px`}) {
    padding-top: 48px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`;

// Mostly copied from `AppBody` but it was getting too hard to maintain backwards compatibility.
// box-shadow: ${({ chainId }) =>
// !!chainId && chainId === ChainId.BNB && "0px 40px 120px 0px #f0b90b29"};
export const SwapWrapper = styled.main<{ chainId?: number }>`
  position: relative;
  background: transparent
  border-radius: 16px;
  border: none;
  padding: 8px;
  padding-top: 12px;

  z-index: ${Z_INDEX.default};
  transition: transform 250ms ease;

  &:hover {
    border: none;
  }
`;

export const ArrowWrapper = styled.div<{ clickable: boolean }>`
  border-radius: 20px;
  height: 28px;
  width: 28px;
  position: relative;
  margin-top: -12px;
  margin-bottom: -12px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border: 4px solid;
  border-color: #f3eefe;
  transition: all 0.25s;

  z-index: 2;
  ${({ clickable }) =>
    clickable
      ? css`
          :hover {
            cursor: pointer;
            opacity: 0.7;
            transform: rotate(-180deg);
            opacity: 1;
          }
        `
      : null}
`;

// styles
export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`;

const SwapCallbackErrorInner = styled.div`
  background-color: ${({ theme }) => transparentize(0.9, theme.accentFailure)};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.825rem;
  width: 100%;
  padding: 3rem 1.25rem 1rem 1rem;
  margin-top: -2rem;
  color: ${({ theme }) => theme.accentFailure};
  z-index: -1;
  p {
    padding: 0;
    margin: 0;
    font-weight: 500;
  }
`;

const SwapCallbackErrorInnerAlertTriangle = styled.div`
  background-color: ${({ theme }) => transparentize(0.9, theme.accentFailure)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 12px;
  min-width: 48px;
  height: 48px;
`;

export function SwapCallbackError({ error }: { error: ReactNode }) {
  return (
    <SwapCallbackErrorInner>
      <SwapCallbackErrorInnerAlertTriangle>
        <AlertTriangle size={24} />
      </SwapCallbackErrorInnerAlertTriangle>
      <p style={{ wordBreak: "break-word" }}>{error}</p>
    </SwapCallbackErrorInner>
  );
}

export const SwapShowAcceptChanges = styled(AutoColumn)`
  background-color: ${({ theme }) =>
    transparentize(0.95, theme.deprecated_primary3)};
  color: ${({ theme }) => theme.accentAction};
  padding: 12px;
  border-radius: 12px;
  margin-top: 8px;
`;
