import { log } from "console";
import { useEffect, useState } from "react";

export function useUSDPriceApi(symbol: string): {
  data?: any;
  isLoading: boolean;
} {
  const PRICES_API = process.env.REACT_APP_ARYZE_USD_PRICES_API + "getPrice";
  const [price, setPrices] = useState(0);

  useEffect(() => {
    async function getData() {
      setPrices(0);
      const request: any = await fetch(`${PRICES_API}?symbol=${symbol}`, {
        method: "GET",
      });
      const result = await request.json();

      if (!result?.payload?.prices[0]?.usdPrice) return;

      setPrices(result?.payload?.prices[0]?.usdPrice);
    }

    if (symbol.length > 0) getData();
  }, [symbol, setPrices]);

  return {
    data: price,
    isLoading: false,
  };
}
