import { Currency, CurrencyAmount, TradeType } from "@aryze/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { WRAPPED_NATIVE_CURRENCY } from "constants/tokens";
import {
  DebounceSwapQuoteVariant,
  useDebounceSwapQuoteFlag,
} from "featureFlags/flags/debounceSwapQuote";
import { useMemo } from "react";
import { InterfaceTrade, QuoteMethod, TradeState } from "state/routing/types";
import { useRoutingAPITrade } from "state/routing/useRoutingAPITrade";
import { useRouterPreference } from "state/user/hooks";

import useAutoRouterSupported from "./useAutoRouterSupported";
import { useClientSideV3Trade } from "./useClientSideV3Trade";
import useDebounce from "./useDebounce";
import useIsWindowVisible from "./useIsWindowVisible";

// Prevents excessive quote requests between keystrokes.
const DEBOUNCE_TIME = 350;

// Temporary until we remove the feature flag.
const DEBOUNCE_TIME_INCREASED = 650;

/**
 * Returns the best v2+v3 trade for a desired swap.
 * @param tradeType whether the swap is an exact in/out
 * @param amountSpecified the exact amount to swap in/out
 * @param otherCurrency the desired output/payment currency
 */
export function useBestTrade(
  tradeType: TradeType,
  amountSpecified?: CurrencyAmount<Currency>,
  otherCurrency?: Currency
): {
  state: TradeState;
  trade?: InterfaceTrade;
  method?: QuoteMethod;
} {
  const { chainId } = useWeb3React();
  const autoRouterSupported = useAutoRouterSupported();
  const isWindowVisible = useIsWindowVisible();

  const debouncedSwapQuoteFlagEnabled =
    useDebounceSwapQuoteFlag() === DebounceSwapQuoteVariant.Enabled;
  const [debouncedAmount, debouncedOtherCurrency] = useDebounce(
    useMemo(
      () => [amountSpecified, otherCurrency],
      [amountSpecified, otherCurrency]
    ),
    debouncedSwapQuoteFlagEnabled ? DEBOUNCE_TIME_INCREASED : DEBOUNCE_TIME
  );

  const isAWrapTransaction = useMemo(() => {
    if (!chainId || !amountSpecified || !debouncedOtherCurrency) return false;
    const weth = WRAPPED_NATIVE_CURRENCY[chainId];
    return (
      (amountSpecified.currency.isNative &&
        weth?.equals(debouncedOtherCurrency)) ||
      (debouncedOtherCurrency.isNative &&
        weth?.equals(amountSpecified.currency))
    );
  }, [amountSpecified, chainId, debouncedOtherCurrency]);

  const shouldGetTrade = !isAWrapTransaction && isWindowVisible;

  const [routerPreference] = useRouterPreference();
  const routingAPITrade = useRoutingAPITrade(
    tradeType,
    amountSpecified ? debouncedAmount : undefined,
    debouncedOtherCurrency,
    routerPreference,
    !(autoRouterSupported && shouldGetTrade) // skip fetching
  );

  const inDebounce =
    (!debouncedAmount && Boolean(amountSpecified)) ||
    (!debouncedOtherCurrency && Boolean(otherCurrency));
  const isLoading = routingAPITrade.state === TradeState.LOADING || inDebounce;
  const useFallback =
    (!autoRouterSupported ||
      routingAPITrade.state === TradeState.NO_ROUTE_FOUND) &&
    shouldGetTrade;

  // only use client side router if routing api trade failed or is not supported
  const bestV3Trade = useClientSideV3Trade(
    tradeType,
    useFallback ? debouncedAmount : undefined,
    useFallback ? debouncedOtherCurrency : undefined
  );

  // only return gas estimate from api if routing api trade is used
  return useMemo(
    () => ({
      ...(useFallback ? bestV3Trade : routingAPITrade),
      ...(isLoading ? { state: TradeState.LOADING } : {}),
    }),
    [bestV3Trade, isLoading, routingAPITrade, useFallback]
  );
}
