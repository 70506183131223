import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import { useCloseModal, useModalIsOpen } from "state/application/hooks";
import { ApplicationModal } from "state/application/reducer";
import styled, { useTheme } from "styled-components/macro";
import { CustomLightSpinner, ThemedText } from "theme";
import { useIsDarkMode } from "theme/components/ThemeToggle";

import Circle from "../../assets/images/blue-loader.svg";
import Modal from "../Modal";

const MOONPAY_DARK_BACKGROUND = "#1c1c1e";
const Wrapper = styled.div<{ isDarkMode: boolean }>`
  // #1c1c1e is the background color for the darkmode moonpay iframe as of 2/16/2023
  background-color: ${({ isDarkMode, theme }) =>
    isDarkMode ? MOONPAY_DARK_BACKGROUND : theme.white};
  border-radius: 20px;
  box-shadow: ${({ theme }) => theme.deepShadow};
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  flex: 1 1;
  min-width: 375px;
  position: relative;
  width: 100%;
`;

const ErrorText = styled(ThemedText.BodyPrimary)`
  color: ${({ theme }) => theme.accentFailure};
  margin: auto !important;
  text-align: center;
  width: 90%;
`;
const StyledIframe = styled.iframe<{ isDarkMode: boolean }>`
  // #1c1c1e is the background color for the darkmode moonpay iframe as of 2/16/2023
  background-color: ${({ isDarkMode, theme }) =>
    isDarkMode ? MOONPAY_DARK_BACKGROUND : theme.white};
  border-radius: 12px;
  bottom: 0;
  left: 0;
  height: calc(100% - 16px);
  margin: 8px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - 16px);
`;
const StyledSpinner = styled(CustomLightSpinner)`
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
`;

const MOONPAY_SUPPORTED_CURRENCY_CODES = [
  "eth",
  "eth_arbitrum",
  "eth_optimism",
  "eth_polygon",
  "weth",
  "wbtc",
  "matic_polygon",
  "polygon",
  "usdc_arbitrum",
  "usdc_optimism",
  "usdc_polygon",
];

export default function FiatOnrampModal() {
  const { account, chainId } = useWeb3React();
  const theme = useTheme();
  const isDarkMode = useIsDarkMode();
  const closeModal = useCloseModal();
  const fiatOnrampModalOpen = useModalIsOpen(ApplicationModal.FIAT_ONRAMP);

  const [signedIframeUrl, setSignedIframeUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchSignedIframeUrl = useCallback(async () => {
    if (!account || !chainId) {
      setError("Please connect an account before making a purchase.");
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const network = [
        { codeName: "ethereum", chainId: "0x1" },
        { codeName: "polygon", chainId: "0x89" },
        { codeName: "celo", chainId: "0xa4ec" },
        { codeName: "bsc", chainId: "0x38" },
        { codeName: "avaxcchain", chainId: "0xa86a" },
        { codeName: "arbitrum", chainId: "0xa4b1" },
        { codeName: "optimism", chainId: "0xa" },
      ].find((e) => Number(e.chainId) == Number(chainId));
      if (!network) return;

      const params = {
        apiKey: process.env.REACT_APP_TRANSAK_PUBLISHABLE_KEY,
        // cryptoCurrencyCode: "USDC",
        defaultCryptoCurrency: "USDC",
        network: network.codeName,
        // defaultNetwork: network.codeName,
        walletAddress: account,
        disableWalletAddressForm: true,
        // productsAvailed: "BUY",
        defaultPaymentMethod: "credit_debit_card",
      };

      const url = objectToURL(process.env.REACT_APP_TRANSAK_LINK!, params);

      setSignedIframeUrl(url);
    } catch (e) {
      console.log("there was an error fetching the link", e);
      setError(e.toString());
    } finally {
      setLoading(false);
    }
  }, [account, isDarkMode, theme.accentAction]);

  useEffect(() => {
    fetchSignedIframeUrl();
  }, [fetchSignedIframeUrl]);

  return (
    <Modal
      isOpen={fiatOnrampModalOpen}
      onDismiss={closeModal}
      height={80 /* vh */}
    >
      <Wrapper data-testid="fiat-onramp-modal" isDarkMode={isDarkMode}>
        {error ? (
          <>
            <ThemedText.MediumHeader>
              <Trans>Moonpay Fiat On-ramp iframe</Trans>
            </ThemedText.MediumHeader>
            <ErrorText>
              <Trans>something went wrong!</Trans>
              <br />
              {error}
            </ErrorText>
          </>
        ) : loading ? (
          <StyledSpinner src={Circle} alt="loading spinner" size="90px" />
        ) : (
          <StyledIframe
            src={signedIframeUrl ?? ""}
            frameBorder="0"
            title="fiat-onramp-iframe"
            isDarkMode={isDarkMode}
          />
        )}
      </Wrapper>
    </Modal>
  );
}

function objectToURL(domain: string, obj: any) {
  var url = "";
  const keys = Object.keys(obj);
  keys.forEach((key) => {
    url += key + "=" + obj[key] + "&";
  });

  return domain + "/?" + url;
}
